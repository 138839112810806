<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <el-form-item prop="datePeriod" label="">
                    <el-date-picker
                        ref="picker"
                        v-model="datePeriod"
                        unlink-panels
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="query.keyword"
                        placeholder="请输入关键词"
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                        oninput="value=value.replace(/[%]/g,'')"
                    />
                </el-form-item>

                <el-form-item>
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="query.brandCode"
                        clearable
                        :codeType="'brand'"
                        placeholder="品牌"
                    ></dataSelect>
                </el-form-item>

                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> 查询 </el-button>
                <el-button
                    class="filter-item"
                    icon="el-icon-search"
                    type="primary"
                    @click="handleExport"
                    style="margin-left: 10px"
                    size="small"
                >
                    导出
                </el-button>
            </el-form>
        </div>

        <el-table ref="dataTable" v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column label="关键词" prop="keyword" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="品牌" prop="brandCode" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="国家" prop="countryCode" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="次数" prop="num" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import imgUpload from '@/components/common/imgUpload/imgUpload';
import X2JS from 'x2js'; //解析xml

export default {
    name: 'UserComment',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader,
        imgUpload
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: false,
            countrysDicts: [],
            brandDicts: [],

            query: {
                page: 1,
                size: 10,
                keyword: '',
                brandCode: null,
                beginTime: null,
                endTime: null
            },
            countryLang: [],
            datePeriod: [],

            pickerOptions: {
                shortcuts: [
                    {
                        text: '今天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '这个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '上一个月',
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '今年',
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    created() {
        this.init();
        this.getDictItems();
    },
    mounted() {
        this.init();
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    methods: {
        init() {
            this.setDateDefault();
            this.handleQuery();
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            if (this.datePeriod != null && this.datePeriod.length > 0) {
                this.query.beginTime = moment(this.datePeriod[0]).format('yyyy-MM-DD 00:00:00');
                this.query.endTime = moment(this.datePeriod[1]).format('yyyy-MM-DD 23:59:59');
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }
            this.doQueryList({ page: 1 });
            console.log('test');
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            this.$store.dispatch('getKeywordPage', this.query).then((res) => {
                this.list = res;
            });
        },

        /**
         * 导出excel
         */
        handleExport() {
            this.query.fileName = '关键词统计数据.xlsx';
            this.$confirm('是否确认导出文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('exportKeyword', this.query).then((res) => {
                        this.$message({
                            type: 'success',
                            message: '导出成功'
                        });
                    });
                })
                .catch(() => {});
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
        },
        cleanQuery() {
            this.query.keyword = '';
            this.query.brandCode = null;
            this.query.beginTime = null;
            this.query.endTime = null;
            this.doQueryList({ page: 1 });
        },
        setDateDefault() {
            let end = new Date();
            let start = new Date(end - 1000 * 60 * 60 * 24 * 6);
            // this.query.beginTime = moment(start).format('YYYY-MM-DD') + ' 23:59:59';
            this.query.beginTime = moment().add(-6, 'days').format('yyyy-MM-DD 00:00:00');
            this.query.endTime = moment().add(-1, 'days').format('yyyy-MM-DD 00:00:00')
            this.datePeriod = [start, end];
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
</style>
